<template>
  <v-dialog
     max-width="600"
     v-model="isShowDialog"
  >
    <v-card>
      <v-card-title class="text-h5 justify-space-between">
        Додати товар
        <v-icon large @click="isShowDialog = false">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-form class="pa-5 form" 
              lazy-validation 
              v-model="isValidForm" 
              ref="form">

          <!--Код тайфун-->
          <div class="d-flex align-center">
            <v-text-field 
              label="Введіть код Тайфун" 
              v-model.trim="product.typhoon_id"
              :error-messages="errorProduct"
            />

            <v-btn 
              class="ml-2"
              color="primary" 
              small
              @click="checkActionProduct()">
              Перевірити
            </v-btn>
          </div>

          <div v-if="product.name">
           <!--Назва-->
            <v-text-field 
              disabled
              label="Назва" 
              v-model="product.name"
              :rules="[v => !!v || errorRequired]"
            />
            <!--URL-->
            <v-text-field 
              disabled
              label="URL" 
              v-model="product.link" 
              :rules="[v => !!v || errorRequired]"
            />
            <!--Ціноутворення-->
            <v-select
              return-object
              item-text="name"
              item-value="id"
              v-model="priceType"
              :items="actionPriceTypeList"
              label="Ціноутворення"
              clearable
              required
              :rules="[v => !!v || errorRequired]"
            ></v-select>
            <!--Ціна-->
            <v-text-field 
              label="Ціна" 
              v-model.trim.number="product.price"
              :rules="[v => !!v || errorRequired]" 
              type="number"
            />
        </div>
      </v-form>

      <!--Кнопки-->
      <v-card-actions 
        class="flex justify-center" 
        v-if="product.name">
        <v-btn 
          color="primary" 
          min-width="150" 
          @click="sendForm()">
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
    <Preloader v-if="isSendingForm" />
  </v-dialog>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapMutations } from 'vuex';

export default { 
  name: 'DialogActionProductAdd',

  inject: ["getAction"],

  data() {
    return {
      product: {},
      priceType: null,
      isValidForm: true,
      isSendingForm: false,
      isShowDialog: true,
      errorRequired: `Це поле обов'язкове`,
      errorProduct: '',
    }
  },

  props: {
    // id акции
    actionId: Number,
    // Данные запроса
    queryData: {
      type: Object,
      default: () => {}
    },
    // Список ценообразований
    actionPriceTypeList: {
      type: Array,
      default: () => []
    },
    // URL запроса
    methodUrl: {
      type: String,
      default: ""
    },
  },

  watch: {
    // Скрыть диалоговое окно при изменении v-model
    isShowDialog() {
      if (!this.isShowDialog) {
        this.$emit("dialog:close", false);
      }
    }
  },

  mounted() {
    this.product = this.queryData;
  },

  methods: {
    ...mapMutations(['setSnackbar']),

    // Добавить акционный продукт
    async sendForm() {
      if (!this.$refs.form.validate()) return;
      this.isSendingForm = true;

      try {
        await HTTP.post(this.methodUrl, {
          ...this.product, 
          price_type_id: this.priceType.id,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
        await this.getAction();
        this.isShowDialog = false;
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSendingForm = false;
      }
    },

    // Получить товар по Тайфун id
    async checkActionProduct() {
      this.errorProduct = '';
      this.isSendingForm = true;
      try {
        let { data } = await HTTP.post('/check-action-product', { 
          typhoon_id: this.product.typhoon_id, 
          lang_key: 'ua' 
        });
        Object.assign(this.product, data.content)
      } catch(error) {
        for(let key in this.product) {
          this.product[key] = '';
        }
        console.warn(error);
        this.errorProduct = 'Товар не знайдено';
      } finally {
        this.isSendingForm = false;
      }
    },
  },
}
</script>

<style scoped lang="sass">
</style>
