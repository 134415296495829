<template>
  <v-container
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-sale"
    >
      <template #title>
        <div class="mb-3">
          Акція: <b>{{ action.langs ? action.langs[0].name : '' }} ({{ action.id }}) </b>
        </div>
      </template>
      
      <v-row class="pl-3 pr-3">
        <v-col cols="12" sm="6">
          <ButtonComeBack />
        </v-col>

        <!--Зберегти: "механіка" + "опис-->
        <v-col cols="12" sm="6" class="text-right">
          <v-btn 
            v-if="isManager || isAdmin"
            color="primary" 
            min-width="150" 
            @click="saveAction()">
            Зберегти: "механіка" + "опис"
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tabMainActive" :show-arrows="true" dark background-color="primary">
            <v-tab 
              v-for="tab in tabsMain" 
              :key="tab.id"
              @click="changeRoute(tab.id)">
              {{ tab.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabMainActive" :touchless="true">
            <!--Таб механика-->
            <v-tab-item :eager="true">
              <v-col cols="12">
                <TabMechanic 
                  ref="tabMechanic"
                  v-model="action" 
                  :actionMechanicList="actionMechanicList" 
                />
              </v-col>
            </v-tab-item>

            <!--Таб описание-->
            <v-tab-item :eager="true">
              <v-col cols="12">
                <TabDescription 
                  ref="tabDescription"
                  v-model="action" 
                  :actionTypeList="actionTypeList"
                  :imagePageBaseUrl="imagePageBaseUrl"
                  :imageBannerBaseUrl="imageBannerBaseUrl"
                  @form:image-change="imagesToSend = $event" 
                />
              </v-col>
            </v-tab-item>

            <!--Таб акионный набор-->
            <v-tab-item :eager="true">
              <v-col cols="12">
                <TabSets
                  :action="action"
                  :actionPriceTypeList="actionPriceTypeList"
                  :actionTypeList="actionTypeList"
                />
              </v-col>
            </v-tab-item>

            <!--Таб промокоды-->
            <v-tab-item :eager="true">
              <v-col cols="12">
                <TabPromocodes :action="action" />
              </v-col>
            </v-tab-item>

            <!--Таб проекты-->
            <v-tab-item :eager="true">
              <v-col cols="12">
                <TabProjects
                  :action="action"
                  :projectList="projectList"
                />
              </v-col>
            </v-tab-item>
            
          </v-tabs-items>
        </v-col>
      </v-row>
      <Preloader v-if="isSending" />
    </material-card>
  </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import { IMAGE_BASE_URL } from '@/env.config';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import TabDescription from './TabDescription.vue';
import TabMechanic from './TabMechanic.vue';
import TabSets from './TabSets.vue';
import TabProjects from './TabProjects.vue';
import TabPromocodes from './TabPromocodes.vue';


export default { 
  name: 'PageActionProfile',
  provide() {
    return {
      getAction: this.getAction,
    };
  },
  data() {
    return {
      imagesToSend: {},
      imagePageBaseUrl: `${IMAGE_BASE_URL}actions/index/`,
      imageBannerBaseUrl: `${IMAGE_BASE_URL}actions/`,
      action: {},
      actionMechanic: null,
      actionMechanicList: [],
      actionTypeList: [],
      actionPriceTypeList: [],
      projectList: [],
      tabMainActive: 0,
      tabsMain: [
        { name: "Механіка", id: 'mechanic' },
        { name: "Опис", id: 'description' },
        { name: "Акційні набори", id: 'sets' },
        { name: "Промокоди", id: 'promocodes' },
        { name: "Проекти", id: 'projects' },
      ],
      // Отправка изменений в акции
      isSending: false,
      errorRequired: `Це поле обов'язкове`,
    }
  },
  components: {
    TabDescription,
    TabMechanic,
    TabSets,
    TabPromocodes,
    TabProjects,
  },
  computed: {
    ...mapGetters(['isAdmin', 'isManager', 'snackbar', 'localization']),
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    ...mapActions(['getLocalization']),

    async init() {
      // Делаем активным таб
      let { tab } = this.$route.query;
      this.tabsMain.forEach((el, index)=>{
        if (tab == el.id) {
          this.tabMainActive = index;
        }
      });

      await this.getAction();
      await this.getLocalization();
      await this.getActionMechanicList();
      await this.getActionTypeList();
      await this.getActionPriceTypeList();
      await this.getProjectList();
    },

    // Создать список ошибок (ошибки надо показать в попапе - потому что табы)
    createListErrorsAction() {
      let isValidMechanic = this.$refs.tabMechanic.$refs.form.validate();
      let isValidDescription = this.$refs.tabDescription.$refs.form.validate();
      let errors = '';
      
      if (!isValidMechanic) {
        errors += 'Помилка на табі "Механіка"</br>';
      }

      if (!isValidDescription) {
        errors += 'Помилка на табі "Опис"</br>';
        for(let item of this.action.langs) {
          if (!item.name) {
            errors += `# Помилка на табі "Опис" - "${item.lang_name}"</br>`
          }
        }
      }
      return errors;
    },

    // Сохранить изменения в акции
    async saveAction() {
      let errors = this.createListErrorsAction();
      if (errors) {
        this.setSnackbar({ type: 'error', text: errors });
        return;
      };

      let isImages = Object.keys(this.imagesToSend).length;
      this.isSending = true;

      try {
        let { id, mis_id, archive, main, singly_main, mechanic, type, date_from, date_to, url, langs } = this.action;
        await HTTP.post('/update-action', {
          id,
          mis_id,
          archive,
          main,
          singly_main,
          mechanic: mechanic.name,
          mechanic_id: mechanic.id,
          action_type: type.name,
          action_type_id: type.id,
          date_from,
          date_to,
          url,
          langs,
        });
        await this.getAction();
        this.setSnackbar({ 
          type: 'success', 
          text: `Акцію збережено. ${ isImages ? 'Виконується завантаження зображень.': '' }` 
        });

        if (!isImages) {
          this.isSending = false;
        } else {
          let unwatch = this.$watch('snackbar.model', (newVal, oldVal) => {
            this.runProcessImagesSend();
            unwatch();
          });
        }
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        this.isSending = false;
      }
    },

    // Запускает процесс отравки изображений, отправкой занимается "ImageUpload"
    async runProcessImagesSend() {
      let errors = '';
      for(let key in this.imagesToSend) {
        let { params, sendImage } = this.imagesToSend[key];
        let result = await sendImage(params);
        // Если при загрузке изображений были ошибки - показать попап
        if (!result.error) {
          delete this.imagesToSend[key];
        } else {
          errors += `Таб Опис: ${result.error}</br>`;
          this.setSnackbar({ type: 'error', text: errors });
          // Редирект при закрытии попапа "setSnackbar $watch" 
          let unwatch = this.$watch('snackbar.model', (newVal, oldVal) => {
            unwatch();
          });
        }
      }
      this.isSending = false;
    },

    // Получить акцию
    async getAction() {
      try {
        let { data } = await HTTP.post('/get-action', {
          action_id: this.$route.params.id,
          lang_key: 'ua',
        });
        let { 
          date_from, 
          date_to, 
          action_type,
          action_type_id, 
          mechanic, 
          mechanic_id,
          url,
          archive,
          id,
          langs,
          main,
          mis_id,
          prodsets,
          projects,
          singly_main,
          promocodes,
        } = data.content;
        // Чтобы небыло лишних полей не делаю Object.assign
        this.action = {
          url,
          archive,
          id,
          langs: langs.map(el => {
            el.banner_image = el.banner_image ? `${this.imageBannerBaseUrl}${el.banner_image}` : '';
            el.image = el.image ? `${this.imagePageBaseUrl}${el.image}` : '';
            return el;
          }),
          main,
          mis_id,
          prodsets,
          projects,
          singly_main,
          isShowDatePickerFrom: false,
          isShowDatePickerTo: false,
          date_from: date_from.slice(0,10),
          date_to: date_to.slice(0,10),
          type: { id: action_type_id, name: action_type },
          mechanic: { id: mechanic_id, name: mechanic },
          promocodes,
        };
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить механики акций
    async getActionMechanicList() {
      try {
        let { data } = await HTTP.post('/get-list-action-mechanic', { 
          lang_key: 'ua',
        });
        this.actionMechanicList = data.content.mechanic;
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить типы акций
    async getActionTypeList() {
      try {
        let { data } = await HTTP.post('/get-list-action-type', { 
          lang_key: 'ua', 
          enabled: 1,
        });
        let { id, name } = this.action.type;
        this.actionTypeList = data.content.type;
        // Проверяем наличие типа который в акции в списке
        let checkType = this.actionTypeList.includes((el) => el.id == id);
        if (!checkType) {
          this.actionTypeList.push({ id, name });
        }
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить типы акций
    async getActionPriceTypeList() {
      try {
        let { data } = await HTTP.post('/get-list-action-price-type', { lang_key: 'ua' });
        this.actionPriceTypeList = data.content['price-type'];
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить список проектов
    async getProjectList() {
      try {
        let { data } = await HTTP.post('/get-projects');
        this.projectList = data.content.map((el) => {
          return {
            project_id: el.id,
            name: el.name,
          }
        });
      } catch(error) {
        console.warn(error);
      }
    },

    // Добавляем query параметр при клике на таб
    changeRoute(tabId) {
      if (this.$route.query.tab != tabId) {
        this.$router.push({ query: { tab: tabId } });
      }
    },
  },
}
</script>

<style scoped lang="sass">
</style>
