<template>
  <v-form 
    @input="$emit('form:change', action)" 
    ref="form"
    lazy-validation
    v-model="isValidForm">
    <!--Тип акції-->
    <v-col cols="12" lg="6">
      <v-select
        return-object
        item-text="name"
        item-value="id"
        v-model="action.type"
        :items="actionTypeList"
        label="Тип акції"
        clearable
        required
        :rules="[v => !!v || errorRequired]"
      ></v-select>
    </v-col>

    <!-- Описание табы -->
    <v-col cols="12" lg="6">
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="lang in action.langs" :key="lang.lang_key">
            {{ lang.lang_name }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-tabs-items v-model="tab">
      <v-tab-item 
        v-for="lang in action.langs" 
        :key="lang.lang_key"
        :eager="true">
        <template>
          <!--Назва-->
          <v-col cols="12" lg="6">
            <v-text-field 
              label="Назва" 
              v-model="lang.name"
              required
              :rules="[v => !!v || errorRequired]" />
          </v-col>

          <!--Анонс-->
          <v-col cols="12" lg="6">
            <v-textarea 
              rows="3"
              :no-resize="true"
              label="Анонс" 
              v-model="lang.preview"/>
          </v-col>

          <!--Банер-->
          <v-col cols="12" lg="6">
            <ImageUpload 
              label="Банер"
              v-model="lang.banner_image" 
              @image-upload:change="changeImage($event, {
                lang_key: lang.lang_key,
                object_image_id: action.id,
                image_type: 'action_banner_image',
              })" 
              :imageBaseUrl="imageBannerBaseUrl" />
          </v-col>

          <!--Фото сторінки -->
          <v-col cols="12" lg="6">
            <ImageUpload 
              label="Фото сторінки"
              v-model="lang.image" 
              @image-upload:change="changeImage($event, {
                lang_key: lang.lang_key,
                lang_name: lang.lang_name,
                object_image_id: action.id,
                image_type: 'action_image',
              })"
              :imageBaseUrl="imagePageBaseUrl" />
          </v-col>

          <!--Повний опис-->
          <v-col cols="12" lg="6">
            <div>Опис:</div>
            <VueEditor 
              :editorToolbar="customToolbar"
              placeholder="Опис" 
              v-model="lang.text" />
          </v-col>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default { 
  name: 'TabDescription',
  data() {
    return {
      // Активный таб
      tab: 0,
      // Картинки для отправки
      imagesToSend: {},
      isValidForm: true,
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ 
          color: ['#114A8C','#000000',
          '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
          '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5',
          '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
        }, 
        { 
          background: ['#114A8C','#000000',
          '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
          '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5',
          '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
        }],
        ["link"],
        ["clean"]
      ],
      errorRequired: `Це поле обов'язкове`,
    }
  },
  props: {
    // Акция
    action: {
      type: Object,
      default: () => {},
    },
    // Типы акций
    actionTypeList: {
      type: Array,
      default: () => [],
    },

    imagePageBaseUrl: {
      type: String,
      default: "",
    },

    imageBannerBaseUrl: {
      type: String,
      default: "",
    }
  },

  components: {
    VueEditor
  },

  model: {
    prop: "action",
    event: "form:change"
  },

  methods: {
    // Создать список картинок для загрузки на сервер
    changeImage(event, params) {
      let key = `${params.lang_key} ${params.image_type}`
      if (event.action) {
        event.params = params;
        this.imagesToSend[key] = event;
      } else {
        delete this.imagesToSend[key];
      }
      this.$emit('form:image-change', this.imagesToSend);
    }
  } 
}
</script>

<style lang="sass">
// Нужно, потому что обрезается инпут
.v-window
  overflow: visible !important
.quillWrapper .ql-tooltip.ql-editing[data-mode="link"]
  z-index: 100

</style>
