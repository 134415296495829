<template>
  <v-col cols="12" lg="6">
    <!--Додавання промокоду в акцію-->
    <div class="text-h3">Додати промокод</div>
    <v-form ref="formAdd" v-model="isValidFormAdd" lazy-validation>
      <v-text-field 
        label="Промокод" 
        v-model.trim="promocode"
        required
        :rules="[v => !!v || errorRequired, v => !(promocode && promocode.length > 50) || errorLength]" />

        <div class="text-right">
          <v-btn 
            min-width="150"
            color="primary" 
            @click="addActionPromocode()">
            Зберегти
          </v-btn>
        </div>
        <Preloader v-if="isSending" />
    </v-form>

    <!--Редагування / выдалення промокоду в акцію-->

    <div class="text-h3 mb-3">Редагувати промокоди</div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="primary--text">
            id
          </th>
          <th width="100%" class="primary--text">
            Промокод
          </th>
          <th class="primary--text">
            Включено
          </th>
          <th class="primary--text">
            Видалити
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in action.promocodes" :key="item.promocode_id">
          <!--id-->
          <td>{{ item.promocode_id }}</td>
          <!--Промокод-->
          <td> {{ item.promocode }} </td>
          <!--Включено-->
          <td class="text-center">
            <v-switch 
              v-model="item.enabled" 
              @click="updateActionPromocode(item.promocode, item.enabled)">
            </v-switch>
          </td>
          <!--delete-->
          <td class="text-center">
            <v-icon color="error" @click="deleteActionPromocode(item.promocode)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <DialogConfitmDelete ref="dialogConfirmDelete" />
    <Preloader v-if="isSending" />
  </v-col>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default { 
  name: 'TabPromocodes',
  inject: ['getAction'],
  data() {
    return {
      // Сообщение об ошибке для обязательных полей
      errorRequired: `Це поле обов'язкове`,
      errorLength: 'Промокод мае бути від 1 до 50 символів',
      isValidFormAdd: true,
      isSending: false,
      promocode: '',
    }
  },

  props: {
    // Акция
    action: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    // Додавання промокоду в акцію
    async addActionPromocode() {
      let isValid = this.$refs.formAdd.validate();
      if (!isValid) return;
      this.isSending = true;

      try {
        await HTTP.post('/add-action-promocode', {
          action_id: this.action.id,
          promocode: this.promocode,
        });
        this.$refs.formAdd.reset();
        await this.getAction();
        this.setSnackbar({ type: 'success', text: 'Успішно збережено' });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Видалення промокоду з акції
    async deleteActionPromocode(promocode) {
      let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
      if (!isConfirmDelete) return;
      this.isSending = true;

      try {
        await HTTP.post('/delete-action-promocode', {
          action_id: this.action.id,
          promocode,
        });
        await this.getAction();
        this.setSnackbar({ type: 'success', text: 'Успішно видалено' });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Видалення промокоду з акції
    async updateActionPromocode(promocode, enabled) {
      this.isSending = true;

      try {
        await HTTP.post('/update-action-promocode', {
          action_id: this.action.id,
          promocode,
          enabled: !!enabled,
        });
        this.setSnackbar({ type: 'success', text: 'Успішно збережено' });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        await this.getAction();
        this.isSending = false;
      }
    },
  }
}
</script>

<style scoped lang="sass">
</style>
