var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"input":function($event){return _vm.$emit('form:change', _vm.action)}},model:{value:(_vm.isValidForm),callback:function ($$v) {_vm.isValidForm=$$v},expression:"isValidForm"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"return-object":"","item-text":"name","item-value":"id","items":_vm.actionTypeList,"label":"Тип акції","clearable":"","required":"","rules":[v => !!v || _vm.errorRequired]},model:{value:(_vm.action.type),callback:function ($$v) {_vm.$set(_vm.action, "type", $$v)},expression:"action.type"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.action.langs),function(lang){return _c('v-tab',{key:lang.lang_key},[_vm._v(" "+_vm._s(lang.lang_name)+" ")])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.action.langs),function(lang){return _c('v-tab-item',{key:lang.lang_key,attrs:{"eager":true}},[[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Назва","required":"","rules":[v => !!v || _vm.errorRequired]},model:{value:(lang.name),callback:function ($$v) {_vm.$set(lang, "name", $$v)},expression:"lang.name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-textarea',{attrs:{"rows":"3","no-resize":true,"label":"Анонс"},model:{value:(lang.preview),callback:function ($$v) {_vm.$set(lang, "preview", $$v)},expression:"lang.preview"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ImageUpload',{attrs:{"label":"Банер","imageBaseUrl":_vm.imageBannerBaseUrl},on:{"image-upload:change":function($event){return _vm.changeImage($event, {
              lang_key: lang.lang_key,
              object_image_id: _vm.action.id,
              image_type: 'action_banner_image',
            })}},model:{value:(lang.banner_image),callback:function ($$v) {_vm.$set(lang, "banner_image", $$v)},expression:"lang.banner_image"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ImageUpload',{attrs:{"label":"Фото сторінки","imageBaseUrl":_vm.imagePageBaseUrl},on:{"image-upload:change":function($event){return _vm.changeImage($event, {
              lang_key: lang.lang_key,
              lang_name: lang.lang_name,
              object_image_id: _vm.action.id,
              image_type: 'action_image',
            })}},model:{value:(lang.image),callback:function ($$v) {_vm.$set(lang, "image", $$v)},expression:"lang.image"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',[_vm._v("Опис:")]),_c('VueEditor',{attrs:{"editorToolbar":_vm.customToolbar,"placeholder":"Опис"},model:{value:(lang.text),callback:function ($$v) {_vm.$set(lang, "text", $$v)},expression:"lang.text"}})],1)]],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }