<template>
  <v-form 
    @input="$emit('form:change', action)" 
    ref="form"
    v-model="isValidForm"
    lazy-validation>
    <v-row>
      <!--Механіка-->
      <v-col cols="12" md="6">
        <v-select
          return-object
          item-text="name"
          item-value="name"
          v-model="action.mechanic"
          :items="actionMechanicList"
          label="Механіка"
          clearable
          required
          :rules="[v => !!v || errorRequired]"
        ></v-select>
      </v-col>

      <!--URL-->
      <v-col cols="12" md="6">
        <v-text-field
          label="URL"
          v-model="action.url"
        />
      </v-col>

      <!--date_from-->
      <v-col cols="12" md="6">
        <v-menu
          ref="menu"
          v-model="action.isShowDatePickerFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="action.date_from"
              label="Дата з"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              required
              :rules="[v => !!v || errorRequired, 
                      !(action.date_from > action.date_to) || errorDate]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="action.date_from"
            no-title
            @input="action.isShowDatePickerFrom = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <!--date_to-->
      <v-col cols="12" md="6">
        <v-menu
          ref="menu"
          v-model="action.isShowDatePickerTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="action.date_to"
              label="Дата по"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              required
              :rules="[v => !!v || errorRequired, 
                      !(action.date_from > action.date_to) || errorDate]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="action.date_to"
            no-title
            @input="action.isShowDatePickerTo = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          class="mt-0"
          v-model="action.main"
          label="Головна"
        ></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          class="mt-0"
          v-model="action.singly_main"
          label="Окремо на головній"
        ></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          class="mt-0"
          v-model="action.archive"
          label="Архів"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default { 
  name: 'TabMechanic',
  data() {
    return {
      // Сообщение об ошибке для обязательных полей
      errorRequired: `Це поле обов'язкове`,
      errorDate: 'Дата "з" не може бути більшою ніж "по"',
      isValidForm: true,
    }
  },
  
  props: {
    // Акция
    action: {
      type: Object,
      default: () => {},
    },
    
    // Механики акции
    actionMechanicList: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "action",
    event: "form:change"
  },
}
</script>

<style scoped lang="sass">
</style>
