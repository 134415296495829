<template>
  <v-col cols="12" md="6">
    <v-form 
      ref="form"
      v-model="isValidForm"
      @input="$emit('form:change', action)" 
      lazy-validation>
        <v-select
          class="mr-2"
          v-model="action.projects" 
          label="Проекти" 
          chips 
          multiple 
          cache-items
          return-object
          item-text="name"
          item-value="project_id"
          :items="projectList" 
          required
          :rules="[v => !!v.length || errorRequired]"
          prepend-icon="mdi-format-list-bulleted-type">
        </v-select>

        <div class="text-right">
          <v-btn 
            min-width="150"
            color="primary" 
            @click="saveProjects()">
            Зберегти
          </v-btn>
        </div>
        <Preloader v-if="isSending" />
    </v-form>
  </v-col>
</template>

<script>
import { mapMutations } from 'vuex';
import { HTTP } from '@/http-common';

export default { 
  name: 'TabProjects',
  inject: ['getAction'],
  data() {
    return {
      // Сообщение об ошибке для обязательных полей
      errorRequired: `Це поле обов'язкове`,
      isValidForm: true,
      isSending: false
    }
  },
  
  props: {
    // Акция
    action: {
      type: Object,
      default: () => {},
    },
    
    // Список проектов
    projectList: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "action",
    event: "form:change"
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    // Сохранить проекты
    async saveProjects() {
      let isValid = this.$refs.form.validate();
      if (!isValid) return;
      this.isSending = true;

      try {
        await HTTP.post('/update-action-projects', {
          action_id: this.action.id,
          projects: this.action.projects,
        });
        await this.getAction();
        this.setSnackbar({ type: 'success', text: 'Успішно збережено' });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },
  }
}
</script>

<style scoped lang="sass">
</style>
