<template>
  <v-form>
    <!--Кнопка добавить акионний набор-->
    <v-btn
      v-if="isManager || isAdmin"
      class="mb-3"
      color="primary" 
      min-width="150"
      @click="addActionProdset()"
    >
      Створити акційний набір 
    </v-btn>

    <!--Наборы-->
    <v-card 
      class="mb-6"
      v-for="(prodset, key) in action.prodsets" 
      :key="prodset.prodset_id">
     
     <!--Набір-->
      <v-card-title class="justify-space-between font-weight-black text-h4">
        <span>
          Набір {{ key + 1 }} (id: {{ prodset.prodset_id }})
        </span>
        <span>
          <span>
            <v-btn
              v-if="isManager || isAdmin"
              class="mr-3"
              color="success" 
              small
              @click="addActionLot(prodset.prodset_id)"
            >
              Створити подарунковий лот 
            </v-btn>
          </span>

          <v-icon
            v-if="isAdmin"
            title="Видалити цей акційний набір"
            color="error"
            @click="deleteActionProdset(prodset.prodset_id)"
          >
            mdi-delete
          </v-icon>
        </span>
      </v-card-title>

      <v-card>
        <v-alert
          class="overflow-x-scroll"
          border="left"
          colored-border
          color="deep-orange"
        >
          <!--Акційні товари-->
          <div class="pr-5 pl-4">
            Акційні товари
          </div>

          <v-simple-table dense>
            <thead>
              <tr>
                <th width="140" class="primary--text">
                  Код товару
                </th>
                <th width="140" class="primary--text">
                  Код Тайфун
                </th>
                <th style="min-width: 200px" class="primary--text">
                  Назва
                </th>
                <th width="120" class="primary--text">
                  Тип ціни
                </th>
                <th width="100" class="primary--text">
                  Ціна
                </th>
                <th width="50"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="position in prodset.positions" :key="position.id">
                <td>{{ position.id }}</td>
                <td>{{ position.typhoon_id }}</td>
                <td>
                  <a :href="position.link" target="_blank">{{ position.name }}</a>
                </td>
                <td>{{ listActionPriceTypeNames[position.price_type] }}</td>
                <td>{{ position.price }}</td>
                <td>
                  <span class="d-flex">
                    <v-icon
                      v-if="isManager || isAdmin"
                      class="mr-4"
                      color="green"
                      @click="toggleDialogActionProductEdit({
                        queryData: { 
                          action_id: action.id,
                          prodset_id: prodset.prodset_id, 
                          goods_id: position.goods_id,
                          position_id: position.id,
                          link: position.link,
                          name: position.name,
                          price: position.price,
                          price_type_id: position.price_type,
                          typhoon_id: position.typhoon_id,
                        }, 
                        method: '/update-action-product',
                        isShow: true
                      })"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="isAdmin"
                      color="error"
                      @click="deleteActionProduct(prodset.prodset_id, position)"
                    >
                      mdi-delete
                    </v-icon>
                  </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div class="d-flex justify-end mt-3">
            <!--Додати акційний товар -->
            <v-btn
              v-if="isManager || isAdmin"
              color="deep-orange" 
              outlined
              small
              @click="toggleDialogActionProductAdd({
                queryData: { 
                  prodset_id: prodset.prodset_id, 
                  action_id: action.id 
                }, 
                method: '/add-action-product',
                isShow: true
              })"
            >
              Додати акційний товар 
            </v-btn>
          </div>
        </v-alert>

        <!--Лоты-->
        <v-card v-for="(lot, key) in prodset.lots" :key="lot.lot_id" v-if="lot">
          <v-alert
            class="overflow-x-scroll"
            border="left"
            colored-border
            color="success"
          >
            <div class="d-flex justify-space-between pr-5 pl-4">
              <span>
                Подарунковий ЛОТ {{ key + 1 }} (id:{{ lot.lot_id }})
              </span>

              <span>
                <v-icon
                  v-if="isAdmin"
                  title="Видалити подарунковий ЛОТ"
                  color="error"
                  @click="deleteActionLot(prodset.prodset_id, lot.lot_id)"
                >
                  mdi-delete
                </v-icon>
              </span>
            </div>

            <v-simple-table dense>
              <thead>
                <tr>
                  <th width="140" class="primary--text">
                    Код товару
                  </th>
                  <th width="140" class="primary--text">
                    Код Тайфун
                  </th>
                  <th class="primary--text">
                    Назва
                  </th>
                  <th width="120" class="primary--text">
                    Тип ціни
                  </th>
                  <th width="100" class="primary--text">
                    Ціна
                  </th>
                  <th width="50"></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="lot_position in lot.lot_positions" :key="lot_position.id">
                  <td>{{ lot_position.id }}</td>
                  <td>{{ lot_position.typhoon_id }}</td>
                  <td>
                    <a :href="lot_position.link" target="_blank">{{ lot_position.name }}</a>
                  </td>
                  <td>{{ listActionPriceTypeNames[lot_position.price_type] }}</td>
                  <td>{{ lot_position.price }}</td>
                  <td>
                    <span class="d-flex">
                      <v-icon
                        v-if="isManager || isAdmin"
                        class="mr-4"
                        color="green"
                        @click="toggleDialogActionProductEdit({
                          queryData: { 
                            action_id: action.id,
                            prodset_id: prodset.prodset_id, 
                            lot_id: lot.lot_id, 
                            goods_id: lot_position.goods_id,
                            lot_position_id: lot_position.id,
                            link: lot_position.link,
                            name: lot_position.name,
                            price: lot_position.price,
                            price_type_id: lot_position.price_type,
                            typhoon_id: lot_position.typhoon_id,
                          }, 
                          method: '/update-action-lot-product',
                          isShow: true
                        })"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        v-if="isAdmin"
                        color="error"
                        @click="deleteActionLotProduct(lot.lot_id, prodset.prodset_id, lot_position)"
                      >
                        mdi-delete
                      </v-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="d-flex justify-end mt-3">
              <!--Додавання товару в лот -->
              <v-btn
                v-if="isManager || isAdmin"
                color="success" 
                outlined
                min-width="150"
                small
                @click="toggleDialogActionProductAdd({
                  queryData: { 
                    prodset_id: prodset.prodset_id, 
                    lot_id: lot.lot_id, 
                    action_id: action.id 
                  }, 
                  method: '/add-action-lot-product',
                  isShow: true
                })"
              >
                Додати товар в лот
              </v-btn>
            </div>
          </v-alert>
        </v-card>
      </v-card>
    </v-card>

    <DialogActionProductAdd
      :actionPriceTypeList="actionPriceTypeList" 
      :queryData="productQueryData"
      :methodUrl="productMethod"
      @dialog:close="toggleDialogActionProductAdd({queryData: {}, method: '', isShow: false})"
      v-if="isShowDialogActionProductAdd"
    />

    <DialogActionProductEdit
      :actionPriceTypeList="actionPriceTypeList" 
      :queryData="productQueryData"
      :methodUrl="productMethod"
      @dialog:close="toggleDialogActionProductEdit({queryData: {}, method: '', isShow: false})"
      v-if="isShowDialogActionProductEdit"
    />

    <!--Диалоговое окно для удаления-->
    <DialogConfitmDelete ref="dialogConfirmDelete" />
    <Preloader v-if="isSending" />
  </v-form>
</template>

<script>
import DialogActionProductAdd from './DialogActionProductAdd.vue';
import DialogActionProductEdit from './DialogActionProductEdit.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { HTTP } from '@/http-common';

export default { 
  name: 'TabSets',
  inject: ['getAction'],

  data() {
    return {
      // Показать / скрыть диалоговое окно добавления товара
      isShowDialogActionProductAdd: false,
      // Показать / скрыть диалоговое окно редактирования товара
      isShowDialogActionProductEdit: false,
      // Указывает на то что в данный момент отправляются данные на сервер
      isSending: false,
      // Данные товара добавления / изменения
      productQueryData: {},
      // Метод
      productMethod: ""
    }
  },

  components: {
    DialogActionProductAdd,
    DialogActionProductEdit,
  },
  
  props: {
    // Акция
    action: {
      type: Object,
      default: () => {},
    },
    // Список типов ценообразований
    actionPriceTypeList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(['isManager', 'isAdmin']),

    listActionPriceTypeNames() {
      let items = {};
      for(let item of this.actionPriceTypeList) {
        items[item.id] = item.name;
      }
      return items;
    }
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    // Створити акційний набір 
    async addActionProdset() {
      this.isSending = true;

      try {
        await HTTP.post('/add-action-prodset', { action_id: this.action.id });
        this.setSnackbar({ type: 'success', text: 'Успiшно створено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Видалити акційний набір 
    async deleteActionProdset(prodset_id) {
      let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
      if (!isConfirmDelete) return;
      this.isSending = true;

      try {
        await HTTP.post('/delete-action-prodset', { 
          action_id: this.action.id,
          prodset_id,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Створити акційний лот
    async addActionLot(prodset_id) {
      this.isSending = true;

      try {
        await HTTP.post('/add-action-lot', { 
          action_id: this.action.id,
          prodset_id,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно створено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Видалення лоту з набору акції
    async deleteActionLot(prodset_id, lot_id) {
      let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
      if (!isConfirmDelete) return;
      this.isSending = true;
      
      try {
        await HTTP.post('/delete-action-lot', { 
          action_id: this.action.id,
          prodset_id,
          lot_id,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },


    // Видалення лоту з набору акції
    async deleteActionLotProduct(lot_id, prodset_id, product) {
      let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
      if (!isConfirmDelete) return;
      this.isSending = true;

      let productId = product.id;
      delete product.id;

      try {
        await HTTP.post('/delete-action-lot-product', {
          ...product, 
          lot_id, 
          prodset_id,
          action_id: this.action.id,
          lot_position_id: productId,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Видалення акційного товару з акції 
    async deleteActionProduct(prodset_id, product) {
      let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
      if (!isConfirmDelete) return;
      this.isSending = true;

      let productId = product.id;
      delete product.id;

      try {
        await HTTP.post('/delete-action-product', {
          ...product, 
          prodset_id,
          action_id: this.action.id,
          position_id: productId,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
        await this.getAction();
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
      } finally {
        this.isSending = false;
      }
    },

    // Показать / скрыть модальное окно добавление товара
    toggleDialogActionProductAdd({queryData, method, isShow}) {
      this.productQueryData = queryData;
      this.productMethod = method;
      this.isShowDialogActionProductAdd = isShow;
    },

    // Показать / скрыть модальное окно редактирования товара
    toggleDialogActionProductEdit({queryData, method, isShow}) {
      this.productQueryData = queryData;
      this.productMethod = method;
      this.isShowDialogActionProductEdit = isShow;
    },

  },
}
</script>

<style scoped lang="sass">
  .overflow-x-scroll
    overflow-x: auto
</style>
